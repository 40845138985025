import "@/css/normalize.css";
import "@/css/style.css";

import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createYmaps } from "vue-yandex-maps";
import router from "./router";

loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(
    createYmaps({
      apikey: "b6e0a45d-a8df-45ce-911a-774268a282b8",
    })
  )
  .mount("#app");
